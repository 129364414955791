
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import AnonContactDialog from './AnonContactDialog.vue';
@Component({
  components: { AnonContactDialog }
})
export default class PostContact extends Vue {
  $refs!: {
    AnonContactDialog: HTMLFormElement;
  };
  /* Properties */
  @Prop({ default: 50 }) titleSize!: number;
  /* Store Actions */
  /* Watchers */
  /* Data */
  /* Computed */
  /* Async Functions */
  goToRegister() {
    this.$router.push({ name: 'Register' });
  }
  openContactDialog() {
    this.$refs.AnonContactDialog.openDialog('openContactDialog');
  }
  /* Utility Functions */
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
