
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import AnonContactForm from './AnonContactForm.vue';
@Component({
  components: { AnonContactForm }
})
export default class AnonContactDialog extends Vue {
  $refs!: {
    AnonContactForm: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  onIsFormValidChanged(val: boolean) {
    this.isFormValid = val;
  }
  /* Data */
  isLoading: boolean = false;
  isFormValid: boolean = false;
  dialog: boolean = false;
  /* Computed */
  /* Async Functions */
  /* Utility Functions */
  openDialog() {
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
