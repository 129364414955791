
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import PostDivider from '@/components/NonLoggedInComponents/PostDivider.vue';
@Component({
  components: { PostDivider }
})
export default class extends Vue {
  /* Properties */
  @Prop() imagePath!: string;
  @Prop() imageAltText!: string;
  /* Store Actions */
  /* Watchers */
  /* Data */
  url: string = '';
  fallbackUrl: string = `${process.env.VUE_APP_IMAGES_URL}/J1l-Logo1.svg`;
  /* Computed */
  imgSrc() {
    return `${process.env.VUE_APP_IMAGES_URL}/${this.imagePath}`;
  }
  /* Async Functions */
  /* Utility Functions */
  /* Loaders */
  /* Mounted */
  mounted() {
    this.url = this.imgSrc();
  }
  /* Created */
  /* Emmited Functions */
}
