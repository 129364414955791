
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import PostCard from '@/components/NonLoggedInComponents/PostCard.vue';
import PostDivider from './PostDivider.vue';
@Component({
  components: {
    PostCard,
    PostDivider
  }
})
export default class PostInfoHeader extends Vue {
  /* Properties */
  @Prop({ default: 770 }) maxWidth!: number;
  @Prop() shouldAddDivider!: boolean;
  /* Store Actions */
  /* Watchers */
  /* Data */
  /* Computed */
  /* Async Functions */
  /* Utility Functions */
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
