
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class PostDivider extends Vue {
  /* Properties */
  @Prop({ default: 26 }) margin!: number;
  /* Store Actions */
  /* Watchers */
  /* Data */
  imgSrc: string = `${process.env.VUE_APP_IMAGES_URL}/logo.svg`;
  /* Computed */
  /* Async Functions */
  /* Utility Functions */
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
